<template lang="pug">
input(v-model="question", placeholder="Question ?")
div#reponse {{ reponse }}
img(:src="source")
</template>

<script>
import axios from "axios"

export default {
    data () {
        return {
            question: "",
            source: null,
            reponse: "Pose moi une question :)"
        }
    },
    name: 'Question',
    watch: {
        question (newQuestion, oldQuestion) {
            if (newQuestion.indexOf("?") != -1) {
                this.getReponse()
            } else {
                this.source = null
                this.reponse = "Pose moi une question :)"
            }
        }
    },
    methods: {
        getReponse () {
            this.reponse = "Je reflechis"
            axios.get("https://yesno.wtf/api").then(resp => {
                this.reponse = resp.data.answer == "yes" ? "Oui" : "Non" 
                this.source = resp.data.image
            }).catch(err => {
                this.reponse = "Impossible de trouver une réponse :/"
            })
        }
    }
}
</script>

<style scoped>
img {
    width: 80%;
    margin: 0 auto;
}
</style>
